import React from 'react';

const IncreaseChancesFAQ = () => ([
    {
      title: 'When do you need to remortgage?',
      description: (
        <>
          The main reason why you would remortgage is when your current mortgage deal comes to an end. If you don't remortgage in time, the lender is likely to move you to their standard variable rate (SVR) which is much more expensive than the cost of your original mortgage deal. <br />
          You may also need to remortgage when you move home or want to release cash for home improvements or move home.
        </>
      ),
    },
    {
      title: 'What are the potential costs associated with remortgaging?',
      description: (
        <>
          Like when you take out your initial mortgage, there are associated costs that come with it.<br/><br/>
          These fees include mortgage broker fees, arrangement fees from your new lender, legal fees (remortgage solicitor fees), valuation fees, and booking fees. You may not be charged all of these but these are associated when it comes to a remortgage or remortgage. <br />
          Additionally, if you are remortgaging early you may also encounter an early repayment charge from the lender for exiting your deal before the mortgage agreement specifies.
        </>
      ),
    },
    {
      title: 'What role do credit scores play in remortgaging costs?',
      description: (
        <>
          Your credit score can help determine what interest rates you're eligible for. The better your credit report the better mortgage deals and interest rates you'll have access to and thus, will make your remortgaging costs cheaper.
        </>
      ),
    },
    {
      title: 'How much paperwork is involved in the remortgage process?',
      description: (
        <>
          While there is paperwork involved, it's often less than when you initially got your mortgage. You'll need to provide proof of income, details about your current mortgage, and other financial information.
        </>
      ),
    },
  ]);

export default IncreaseChancesFAQ;
